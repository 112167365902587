<!--
   k-chip

   Componente para facilitar o uso dos v-chip em listas
   
   Ele deve possuir uma cor clara de fundo e uma cor de texto contrastante
   simulando a propriedade "text" que existe nos v-alert. A cor de fundo
   deve ter transparência para ficar bonita no tema escuro
   
   @Exemplo

   Este exemplo imprimirá um v-chip com cor de fundo roxa com opacidade de 20% e cor
   de texto roxo escuro no tema branco e roxo claro no tema escuro
   
   <k-chip icon="mdi-pencil" purple>GET</k-chip>
-->

<template>
  <v-chip
    :x-small="!medium"
    :small="medium"
    class="k-chip mr-1"
    :class="classes"
    v-bind="$attrs"
    :style="styles"
    @click.stop="$emit('click')"
  >
    <!-- Se tiver ícone -->
    <v-icon v-if="icon" left x-small>{{ icon }}</v-icon>
    <!-- Conteúdo -->
    <slot></slot>
  </v-chip>
</template>

<script>
import Colors from "colors-formater";

export default {
  props: {
    icon: String,
    // Tamanho do chip
    medium: Boolean,
    // Nome da cor
    color: String,
    // Cores prévias
    red: [Boolean, Number],
    pink: [Boolean, Number],
    purple: [Boolean, Number],
    indigo: [Boolean, Number],
    blue: [Boolean, Number],
    cyan: [Boolean, Number],
    teal: [Boolean, Number],
    green: [Boolean, Number],
    lime: [Boolean, Number],
    yellow: [Boolean, Number],
    amber: [Boolean, Number],
    orange: [Boolean, Number],
    grey: [Boolean, Number],
    // Cores customizadas em qualquer formato
    custom: String,
    alpha: { type: Number, default: 0.2 }, //
  },

  computed: {
    classes() {
      if (this.custom) return;

      //
      const colors = {
        red: this.red,
        pink: this.pink,
        purple: this.purple,
        indigo: this.indigo,
        blue: this.blue,
        cyan: this.cyan,
        teal: this.teal,
        green: this.green,
        lime: this.lime,
        yellow: this.yellow,
        amber: this.amber,
        orange: this.orange,
        grey: this.grey,
      };

      if (this.color) {
        colors[this.color] = true;
      }

      return Object.keys(colors).find(color => colors[color]) || "grey";
    },

    styles() {
      if (this.custom) {
        const color = Colors(this.custom);

        return {
          color: this.$vuetify.theme.isDark
            ? color.lighten(0.9).toHex()
            : color.toHex(),
          "background-color": `${color.toRGBAString(this.alpha)}!important`,
        };
      }

      return null;
    },
  },
};
</script>



<style lang="scss" scoped>
.k-chip {
  &.red {
    $color: #f44336;
    background-color: rgba($color, 0.2) !important;

    &.theme--dark,
    &.theme--dark .v-icon {
      color: lighten($color, 30%);
    }

    &.theme--light,
    &.theme--light .v-icon {
      color: darken($color, 10%);
    }
  }

  &.pink {
    $color: #e91e63;
    background-color: rgba($color, 0.2) !important;

    &.theme--dark,
    &.theme--dark .v-icon {
      color: lighten($color, 30%);
    }

    &.theme--light,
    &.theme--light .v-icon {
      color: darken($color, 10%);
    }
  }

  &.purple {
    $color: #9c27b0;
    background-color: rgba($color, 0.2) !important;

    &.theme--dark,
    &.theme--dark .v-icon {
      color: lighten($color, 30%);
    }

    &.theme--light,
    &.theme--light .v-icon {
      color: darken($color, 10%);
    }
  }

  &.indigo {
    $color: #3f51b5;
    background-color: rgba($color, 0.2) !important;

    &.theme--dark,
    &.theme--dark .v-icon {
      color: lighten($color, 30%);
    }

    &.theme--light,
    &.theme--light .v-icon {
      color: darken($color, 10%);
    }
  }

  &.blue {
    $color: #2196f3;
    background-color: rgba($color, 0.2) !important;

    &.theme--dark,
    &.theme--dark .v-icon {
      color: lighten($color, 30%);
    }

    &.theme--light,
    &.theme--light .v-icon {
      color: darken($color, 10%);
    }
  }

  &.cyan {
    $color: #00bcd4;
    background-color: rgba($color, 0.2) !important;

    &.theme--dark,
    &.theme--dark .v-icon {
      color: lighten($color, 30%);
    }

    &.theme--light,
    &.theme--light .v-icon {
      color: darken($color, 10%);
    }
  }

  &.teal {
    $color: #009688;
    background-color: rgba($color, 0.2) !important;

    &.theme--dark,
    &.theme--dark .v-icon {
      color: lighten($color, 30%);
    }

    &.theme--light,
    &.theme--light .v-icon {
      color: darken($color, 10%);
    }
  }

  &.green {
    $color: #4caf50;
    background-color: rgba($color, 0.2) !important;

    &.theme--dark,
    &.theme--dark .v-icon {
      color: lighten($color, 30%);
    }

    &.theme--light,
    &.theme--light .v-icon {
      color: darken($color, 10%);
    }
  }

  &.lime {
    $color: #cddc39;
    background-color: rgba($color, 0.2) !important;

    &.theme--dark,
    &.theme--dark .v-icon {
      color: lighten($color, 30%);
    }

    &.theme--light,
    &.theme--light .v-icon {
      color: darken($color, 10%);
    }
  }

  &.yellow {
    $color: #ffeb3b;
    background-color: rgba($color, 0.4) !important;

    &.theme--dark,
    &.theme--dark .v-icon {
      color: lighten($color, 30%);
    }

    &.theme--light,
    &.theme--light .v-icon {
      color: darken($color, 30%);
    }
  }

  &.amber {
    $color: #ffc107;
    background-color: rgba($color, 0.2) !important;

    &.theme--dark,
    &.theme--dark .v-icon {
      color: lighten($color, 30%);
    }

    &.theme--light,
    &.theme--light .v-icon {
      color: darken($color, 10%);
    }
  }

  &.orange {
    $color: #ff9800;
    background-color: rgba($color, 0.2) !important;

    &.theme--dark,
    &.theme--dark .v-icon {
      color: lighten($color, 30%);
    }

    &.theme--light,
    &.theme--light .v-icon {
      color: darken($color, 10%);
    }
  }

  &.grey {
    $color: #9e9e9e;
    background-color: rgba($color, 0.2) !important;

    &.theme--dark,
    &.theme--dark .v-icon {
      color: lighten($color, 30%);
    }

    &.theme--light,
    &.theme--light .v-icon {
      color: darken($color, 30%);
    }
  }
}
</style>
